<template>
  <div class="eatery">
    <!-- height:calc(100vh - 140px);overflow:auto -->
    <Module-Header title="Eatery">
      <!-- <el-button class="export" @click="exportExcel" v-if="adminUserInfo.position == 1"><img src="@/assets/images/link.png" />Export Login</el-button> -->
      <el-button class="default" @click="importExcel" v-if="adminUserInfo.position == 1">Import New</el-button>
      <el-button class="default" @click="$router.push('/eatery/add')" v-if="adminUserInfo.position == 1">Create New</el-button>
    </Module-Header>

    <div class="search-box">
      <el-input placeholder="Keywords(e.g. eatery ID or name)" prefix-icon="el-icon-search" v-model="listQ.like_number_or_name" style="flex:2;"></el-input>
      <el-select v-model="listQ.eatery_group_id" clearable v-if="adminUserInfo.position == 1"
        :placeholder="'All groups'" style="flex:1;margin-left:20px">
        <el-option
          v-for="(item,index) in groups" :key="index" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="listQ.status" clearable :placeholder="'All status'" style="flex:1;margin:0 20px">
        <el-option
          v-for="(item,index) in status" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="listQ.approval_status" clearable :placeholder="'All approval status'" style="flex:1" v-if="false">
        <el-option
          v-for="(item,index) in approvalStatus" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class="table-box">
      <el-table :data="tableData" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
        :prop="its.prop" :label="its.label" :min-width="its.width" sortable>
        </el-table-column>
        <el-table-column width="130" align="center">
          <template slot-scope="scope">
            <div style="display:flex">
              <img src="@/assets/images/edit.png" v-if="adminUserInfo.position == 1" style="margin-right: 26px;" @click="$router.push({ path: `/eatery/edit/${scope.row.id}`})" />
              <img src="@/assets/images/preview.png" class="icon" v-else @click="$router.push({ path: `/eatery/edit/${scope.row.id}`})" />
              <img src="@/assets/images/delete.png" v-if="adminUserInfo.position == 1" @click="del(scope.row.id)"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
        @pagination="getData"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { getEateriesGroup } from '@/api/eateryGroup'
import { getEateries, delEateries, bulkImportEateries } from '@/api/eatery'
import exportToExcel from "@/utils/exportExcel";
import { read, utils } from 'xlsx'
import { _debounce } from '@/utils/throttle'
import { getSetting } from '@/api/setting'
import { District } from './components/profile/profile.js'
import verification from './verification'

export default {
  components: { Pagination },
  data() {
    return {
      form: {
        group:null,
        status:null,
        approvalStatus:null,
      },
      total: 0,
      listQ:{
        _start: 1,
        _limit: 20,
        like_number_or_name: '',
        status: -1,
        eatery_group_id: '',
        approval_status: -1
      },
      tableCate: [
        {label: 'Eatery ID', prop: 'number', width: '160'},
        {label: 'Name', prop: 'handleName', width: '220'},
        {label: 'Group', prop: 'group', width: '160'},
        {label: 'Status', prop: 'plStatus', width: '140'},
        // {label: 'Approval Status', prop: 'plApprovalStatus', width: '220'},
        {label: 'iBeacon MINOR Value', prop: 'minor', width: '240'},
        {label: 'Location ID', prop: 'locationId', width: '220'},
      ], 
      tableData: [],
      groups:[],
      groupName: '',
      status:[
        {label: 'All status', value: -1},
        {label: 'Enabled', value: 0},
        {label: 'Disabled', value: 1},
      ],
      approvalStatus:[
        {label: 'All approval status', value: -1},
        {label: 'Approved', value: 0}, 
        {label: 'Pengding', value: 1}, // Pengding
        {label: 'Rejected', value: 2}, // Rejected
      ],

      CateringType: []
    };
  },
  watch:{
    listQ:{
      handler(val){
        if(val !==null){
          _debounce(this.getData, 500)
        }
      },
      deep:true
    },
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  methods: {
    importExcel() {
      let file = document.createElement('input')
      file.type = 'file'
      file.onchange = this.readFile

      file.click()
    },
    readFile(e){//上传文件后读取excel文件内容
      let file = e.target.files[0];
      const types = ["xlsx", "xls"];
      const arr = file.name.split(".");
      //判断文件是否为excel文件
      if (!types.find(item => item === arr[arr.length - 1])) {
        alert('请选择正确的文件类型');
        return;
      }
      let reader = new FileReader();
      //启动函数
      reader.readAsBinaryString(file);
      reader.onload = e => {
        //workbook存放excel的所有基本信息
        let workbook = read(e.target.result, { type: "binary", cellDates: true });
        //定义sheetList中存放excel表格的sheet表，就是最下方的tab
        let sheetList = workbook.SheetNames;
        //读取文件内容，（第一个sheet里的内容）
        // range：设置从第几行开始读取内容
        let json = utils.sheet_to_json(workbook.Sheets[sheetList[0]],{range: 0});
        // console.log(this.CateringType)
        // console.log(this.groups)
        if(verification(json, this.groups))
          this.handleXlsxData(json)
        else this.$message.error('檔案數據不完整，請重新檢查！')
      };
    },
    handleXlsxData(json) {
      let arr = []
      json.filter((its) => {
        let group = this.groups.filter(itsg => itsg.number == its['Eatery Group'] && itsg)[0],
        eatery_group_id = group ? group.id : '',
        [catering] = this.CateringType.filter(itsc => {
          console.log(itsc.zhHant,  its['Catering Type'])
          return itsc.zhHant == its['Catering Type'] && itsc
        }),
        district = {}, area = {}
        District.filter(itsd => {
          if(itsd.label.indexOf(its[`District`]) > -1) {
            district = {
              "zh_hant": itsd.label.split('/')[1],
              "en": itsd.label.split('/')[0]
            }

            itsd.children.filter(item => {
              if(item.indexOf(its['Area']) > -1) {
                area = {
                  "zh_hant": item.split('/')[1],
                  "en": item.split('/')[0]
                }
              }
            })
          }
        })

        let obj = {
          "name": {
            "zh_hant": its[`Eatery Name (TC)`],
            "en": its[`Eatery Name (EN)`]
          },
          "logo": its[`Logo (image filename)`] ? this.imgUrl + its[`Logo (image filename)`] : '',
          "eatery_group": {
            "id": eatery_group_id
          },
          "catering_type": {
            "zh_hant": catering.zhHant,
            "en": catering.en
          },
          "is_bring_your_own_carrier": its[`BYOC`] == 1,
          "location": {
            "district": district,
            "area": area,
            "address": {
              "zh_hant": its[`Address (TC)`],
              "en": its[`Address (EN)`]
            },
            "map_url": its[`Map URL`]
          },
          "contact": {
            "name": its[`Contact Person`] || '',
            "phone_number": its[`Contact No.`] || '',
            "email_address": its[`Contact Email address`] || ''
          },
          "general": {
            "zh_hant": its[`General T&C (TC)`] || '',
            "en": its[`General T&C (EN)`] || ''
          },
          "beacon": {
            "uuid": "f286c79d-2b0f-9b2e-675d-3fc45f207954",
            "major": Math.floor(Math.random() * 65535 + 1),
            "minor": its[`iBeacon MINOR Value`],
            "loc": its[`Location ID (Loc)`]
          },
          "reward_redemptions": [],
          "user": {
            "username": its[`Username`],
            "password": its[`Password`]
          },
          "support_campaign_type": its[`走即棄容器`] == 1 && its[`走即棄餐具`] == 1 ?
              0 : ((its[`走即棄容器`] && 2) || (its[`走即棄餐具`] && 1))
        }
        
        if(its[`Have Offer 1?`] == 1) obj['reward_redemptions'].push({
          "stamp_costing_number": 6,
          "item": {
            "zh_hant": its[`Offer-1 Item (TC)`],
            "en": its[`Offer-1 Item (EN)`]
          },
          "general": {
            "zh_hant": its[`Offer-1 T&C (TC)`] || '',
            "en": its[`Offer-1 T&C (EN)`] || ''
          },
          "type": 0
        })
        if(its[`Have Offer 2?`] == 1) obj['reward_redemptions'].push({
          "stamp_costing_number": 12,
          "item": {
            "zh_hant": its[`Offer-2 Item (TC)`],
            "en": its[`Offer-2 Item (EN)`]
          },
          "general": {
            "zh_hant": its[`Offer-2 T&C (TC)`] || '',
            "en": its[`Offer-2 T&C (EN)`] || ''
          },
          "type": 1
        })

        arr.push(obj)
      })

      console.log(arr)
      bulkImportEateries(arr).then(() => {
        this.$message.success('導入成功')
        this.getData()
      }).catch(err => {
        this.$message.error(err.data.error_message)
      })
    },
    exportExcel() {
      let data = this.tableData,
      tHeader = [],
      filterVal = [],
      title = 'eatery'

      this.tableCate.filter(its => {
        tHeader.push(its.label)
        filterVal.push(its.prop)
      })
      
      exportToExcel(data, title, tHeader, filterVal, () => {})
    },
    del(id) {
      this.$popUp.config({
        content: 'Please confirm whether to Delete',
        confirm: {
          e: () => {
            delEateries(id).then(() => {
              this.$popUp.removePopup()
              this.listQ._start = 1
              this.getData()
            })
          }
        }
      })
    },
    findSetting() {
      getSetting().then((v) => {
        this.CateringType = v.data.suggestedSearchLabels.cateringType
      })
    },
    getData() {
      let {listQ} = this, 
      form = {
        _start: listQ._start - 1,
        _limit: listQ._limit,
        eatery_group_id: listQ.eatery_group_id,
        like_number_or_name: listQ.like_number_or_name,
      };
      listQ.status != -1 && (form.status = listQ.status)
      listQ.approval_status != -1 && (form.approval_status = listQ.approval_status)

      getEateries(form).then((v) => {
        v.data.filter(its => {
          its.handleName = its.name.zhHant
          its.group = its.eateryGroup ? `${its.eateryGroup.name.zhHant}(${its.eateryGroup.eateries.length})` : ''
          its.plStatus = this.status[its.status + 1].label
          its.plApprovalStatus = this.approvalStatus[its.approvalStatus + 1].label
          its.minor = its.beacon.minor
          its.locationId = its.beacon.loc
        })
        this.tableData = v.data;
        this.total = Number(v.headers["x-total-count"]);
      })
    },
    getEateryGroups() {
      let form = {
        like_eatery_group_id_or_name: '',
        name: '',
        _start: 0,
        _limit: 10000,
      }

      getEateriesGroup(form).then(v => {
        this.groups = [
          {name: 'All Group', id: ''}
        ]
        v.data.filter(its => {
          this.groups.push({name: its.name.zhHant, id: its.id, number: its.number})
        })
      })
    },
    statusChange(){

    },
    approvalChange(){

    },
    search(){
      this.getData()
    },
  },
  created(){
    if(this.adminUserInfo.position == 2) {
      this.listQ.eatery_group_id = this.adminUserInfo.eateryGroupId
    }

    // 获取分组
    this.getEateryGroups()
    this.getData()
    this.findSetting()
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
.eatery {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}
.search-box{
  display: flex;
  font-size:0.875rem;
  width:100%;
  margin-top:50px;
}

.icon {
  width: 30px;
  height: auto;
  cursor: pointer;
}

::v-deep {
  .el-icon-search{
    color: #000;
  }
}
</style>