import http from '@/utils/http'

export function getEateries(params){
  return http('/eateries', {params})
}

export function addEateries(data){
  return http.post('/eateries', data)
}

export function getEateriesDetail(id){
  return http(`/eateries/${id}`)
}

export function editEateriesDetail(id, data){
  return http.put(`/eateries/${id}`, data)
}

export function delEateries(id){
  return http.delete(`/eateries/${id}`)
}

export function disableEateries(id){
  return http.post(`/eateries/${id}/disable_requests`)
}

export function enableEateries(id){
  return http.post(`/eateries/${id}/enable_requests`)
}

export function eateriesSummary(params){
  return http.get(`/eatery_summary`, {params})
}

export function bulkImportEateries(data){
  return http.post(`/bulk_import_eateries`, data)
}