export const District = [
  {
    label: 'Hong Kong Island/香港島',
    children: [
      'Sai Wan/西環', 'Shek Tong Tsui/石塘咀', 'Sai Ying Pun/西營盤', 'Kennedy Town/堅尼地城', 'Sheung Wan/上環', 'Central/中環',
      'Admiralty/金鐘', 'Mid-levels/半山', 'The Peak/山頂', 'Wan Chai/灣仔', 'Causeway Bay/銅鑼灣', 'Happy Valley/跑馬地', 'Tai Hang/大坑',
      'Tin Hau/天后', 'North Point/北角', 'Quarry Bay/鰂魚涌', 'Taikoo/太古', 'Sai Wan Ho/西灣河', 'Shau Kei Wan/筲箕灣', 'Heng Fa Chuen/杏花邨',
      'Chai Wan/柴灣', 'Pok Fu Lam/薄扶林', 'Cyberport/數碼港', 'Aberdeen/香港仔', 'Ap Lei Chau/鴨脷洲', 'Deep Water Bay/深水灣', 'Wong Chuk Hang/黃竹坑',
      'Repulse Bay/淺水灣', 'Stanley/赤柱', 'Shek O/石澳',
    ]
  },
  {
    label: 'Kowloon/九龍',
    children: [
      'Mei Foo/美孚', 'Lai Chi Kok/荔枝角', 'Cheung Sha Wan/長沙灣', 'Sham Shui Po/深水埗', 'Prince Edward/太子', 'Mong Kok/旺角',
      'Yau Ma Tei/油麻地', 'Tai Kok Tsui/大角咀', 'Jordan/佐敦', 'Tsim Sha Tsui/尖沙咀', 'Hung Hom/紅磡', 'To Kwa Wan/土瓜灣',
      'Ho Man Tin/何文田', 'Shek Kip Mei/石硤尾', 'Kowloon City/九龍城', 'Kowloon Tong/九龍塘', 'San Po Kong/新蒲崗', 'Wong Tai Sin/黃大仙',
      'Lok Fu/樂富', 'Diamond Hill/鑽石山', 'Tsz Wan Shan/慈雲山', 'Choi Hung/彩虹', 'Kowloon Bay/九龍灣', 'Ngau Tau Kok/牛頭角', 'Kwun Tong/觀塘',
      'Lam Tin/藍田', 'Yau Tong/油塘', 'Lei Yu Mun/鯉魚門'
    ]
  },
  {
    label: 'New Territories/新界',
    children: [
      'Hang Hau/坑口', 'Tseung Kwan O/將軍澳', 'Sai Kung/西貢', 'Po Lam/寶琳', 'Lau Fau Shan/流浮山', 'Tin Shui Wai/天水圍',
      'Yuen Long/元朗', 'Sham Tseng/深井', 'Tsing Yi/青衣', 'Tuen Mun/屯門', 'Ma Wan/馬灣', 'Kwai Fong/葵芳', 'Kwai Chung/葵涌',
      'Tsuen Wan/荃灣', 'Lok Ma Chau/落馬洲', 'Sheung Shui/上水', 'Lo Wu/羅湖', 'Tai Po/大埔', 'Fanling/粉嶺', 'Tai Wo/太和',
      'Ma On Shan/馬鞍山', 'Tai Wai/大圍', 'Sha Tin/沙田', 'Fo Tan/火炭',
    ]
  },
  {
    label: 'Outlying Islands/離島',
    children: [
      'Lantau Island/大嶼山', 'Tung Chung/東涌', 'Lamma Island/南丫島', 'Cheung Chau/長洲', 'Peng Chau/坪洲',
    ]
  },
]

export const CateringType = [
  '中菜', '西餐廳', '快餐店', '亞洲菜餐廳', '茶餐廳',
]