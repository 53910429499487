export default function verification(arr, groups) {
  console.log(arr)
  let initDataLength = arr.length,
  field = [`Eatery Name (TC)`, `Eatery Name (EN)`, `BYOC`, `District`, `Area`, 
    `Address (TC)`, `Address (EN)`, `Map URL`, `Username`, `Password`, `iBeacon MINOR Value`, `Location ID (Loc)`
  ],
  fiterLength = arr.map(its => {

    let group = groups.filter(itsg => itsg.number == its['Eatery Group'] && itsg)[0]
    if(!group) return false

    for(let i = 0; i < field.length; i++) {
      if(its[field[i]] == undefined){
        console.log(field[i])
        return false
      }
      else {
        let numberTypeField = [`BYOC`, `iBeacon MINOR Value`, `Location ID (Loc)`]
        
        if(!numberTypeField.includes(field[i]) && its[field[i]] == ''){
          console.log(field[i])
          return false
        }
      }
    }

    if(its[`走即棄容器`] == 0 && its[`走即棄餐具`] == 0) return false

    if(its[`Have Offer 1?`] == 1) {
      if(!its[`Offer-1 Item (TC)`] 
        || !its[`Offer-1 Item (EN)`]){
        console.log('Have Offer 1')
        return false
      }
    }

    if(its[`Have Offer 2?`] == 2) {
      if(!its[`Offer-2 Item (TC)`] 
        || !its[`Offer-2 Item (EN)`]){
        console.log('Have Offer 2')
        return false
      }
    }

    return true
  }).filter(its => its && its).length
  
  return initDataLength == fiterLength
}