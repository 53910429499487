// 防抖
var timer = null;
export function _debounce(fn, delay) {
  var delays = delay || 200;

  if (timer != null) {
    clearTimeout(timer);
    timer = null
  }
  
  timer = setTimeout(() => {
    fn.apply(this, arguments);
  }, delays);
}
// 节流
export function _throttle(fn, interval) {
  var last;
  var timer;
  var intervals = interval || 200;
  return function () {
    var th = this;
    var args = arguments;
    var now = +new Date();
    if (last && now - last < intervals) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(th, args);
      }, intervals);
    } else {
      last = now;
      fn.apply(th, args);
    }
  }
}